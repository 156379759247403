import {
  init,
  BrowserTracing,
  vueRouterInstrumentation,
  createTracingMixins,
  attachErrorHandler,
  setContext,
  setTag,
  setUser,
  addBreadcrumb,
  Breadcrumb
} from '@sentry/vue'
import * as FullStory from '@fullstory/browser'
import SentryFullStory from '@sentry/fullstory'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin({
  name: 'monitoring',
  parallel: true,
  setup: (nuxtApp) => {
    const { public: config } = useRuntimeConfig()
    const { vueApp } = nuxtApp

    if (config.environment !== 'production') {
      return
    }

    FullStory.init({ orgId: config.FULLSTORY_ORG_ID })

    init({
      app: [vueApp],
      dsn: config.SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(
            nuxtApp.$router as any
          ),
          tracePropagationTargets: ['localhost', 'beta.inventr.co', /^\//]
        }),
        new SentryFullStory('inventr-e0', { client: FullStory })
      ],
      logErrors: true,
      tracesSampleRate: 1.0,
      environment: config.environment
    })

    vueApp.mixin(
      createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update']
      })
    )

    attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })

    return {
      provide: {
        sentrySetContext: (n: string, context: Record<string, unknown>) =>
          setContext(n, context),
        sentrySetUser: (user: User) => {
          setUser(user)
          FullStory.identify(user.id, {
            email: user.email,
            displayName: user.name,
            roles: user.roles,
            workspaceName: user.workspace?.name
          })
        },
        sentrySetTag: (tagName: string, value: Primitive) =>
          setTag(tagName, value),
        sentryAddBreadcrumb: (breadcrumb: Breadcrumb) =>
          addBreadcrumb(breadcrumb)
      }
    }
  }
})
