import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (routes) => [
    ...routes,
    {
      path: '/disclosures/:pathMatch(.*)*',
      redirect: (to) => to.fullPath.replaceAll('disclosures', 'inventions')
    }
  ]
}
