import { default as corpusgOlfOVbgzOMeta } from "/workspace/pages/admin/corpus.vue?macro=true";
import { default as index8x3i9klHioMeta } from "/workspace/pages/admin/index.vue?macro=true";
import { default as patent_45updateshffxULJ6RqMeta } from "/workspace/pages/admin/patent-updates.vue?macro=true";
import { default as workspacesG42vvgxlcIMeta } from "/workspace/pages/admin/workspaces.vue?macro=true";
import { default as admintMKYXMmtvkMeta } from "/workspace/pages/admin.vue?macro=true";
import { default as forgot_45passwordd41MVLIjg6Meta } from "/workspace/pages/auth/forgot-password.vue?macro=true";
import { default as loginipm5N7UCSPMeta } from "/workspace/pages/auth/login.vue?macro=true";
import { default as _91token_93Dh1nocMFaNMeta } from "/workspace/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_93iw69IV76ZxMeta } from "/workspace/pages/auth/verify-email/[token].vue?macro=true";
import { default as index2lrBtCOh90Meta } from "/workspace/pages/dashboard/index.vue?macro=true";
import { default as add_45patentu4JCIxFKncMeta } from "/workspace/pages/dashboard/overview/add-patent.vue?macro=true";
import { default as overviewrZ0oFTCuW0Meta } from "/workspace/pages/dashboard/overview.vue?macro=true";
import { default as timelineueXOanD89oMeta } from "/workspace/pages/dashboard/timeline.vue?macro=true";
import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91questionId_937VVUn16vakMeta } from "/workspace/pages/inventions/index/create/[questionId].vue?macro=true";
import { default as indexfhdNhWN6PyMeta } from "/workspace/pages/inventions/index/create/index.vue?macro=true";
import { default as _91questionId_93irOR8igLjUMeta } from "/workspace/pages/inventions/index/edit/[disclosureId]/[questionId].vue?macro=true";
import { default as _91disclosureId_93LQBHKGPJJ9Meta } from "/workspace/pages/inventions/index/edit/[disclosureId].vue?macro=true";
import { default as _91flowId_93zcmZkzBkE1Meta } from "/workspace/pages/inventions/index/flows/edit/[flowId].vue?macro=true";
import { default as flows6eQFqgagZjMeta } from "/workspace/pages/inventions/index/flows.vue?macro=true";
import { default as indexnCsYthJgBdMeta } from "/workspace/pages/inventions/index.vue?macro=true";
import { default as _91patentDraftId_939eID63Tqb0Meta } from "/workspace/pages/inventions/patent-drafts/edit/[patentDraftId].vue?macro=true";
import { default as patent_45draftsGV6mJ1yR4PMeta } from "/workspace/pages/inventions/patent-drafts.vue?macro=true";
import { default as inventionsKsTaqEKHSjMeta } from "/workspace/pages/inventions.vue?macro=true";
import { default as add_45reminder3o9MJzFNbtMeta } from "/workspace/pages/patents/[patentId]/add-reminder.vue?macro=true";
import { default as _91reminderId_93Mb1ldraEapMeta } from "/workspace/pages/patents/[patentId]/edit-reminder/[reminderId].vue?macro=true";
import { default as edituxNj3Bv85bMeta } from "/workspace/pages/patents/[patentId]/edit.vue?macro=true";
import { default as _91patentId_93TwZn2oo2LvMeta } from "/workspace/pages/patents/[patentId].vue?macro=true";
import { default as indexyRGpepmXrnMeta } from "/workspace/pages/patents/index.vue?macro=true";
import { default as patentsLc1QXiL9YTMeta } from "/workspace/pages/patents.vue?macro=true";
import { default as _91token_93KmJNaGd7gAMeta } from "/workspace/pages/register/accept-invite/[token].vue?macro=true";
import { default as _91token_93Ah708XYd80Meta } from "/workspace/pages/register/accept-workspace-request/[token].vue?macro=true";
import { default as confirm_45accounte1aFoPDN1cMeta } from "/workspace/pages/register/confirm-account.vue?macro=true";
import { default as import_45patentsqi0LEQtRPBMeta } from "/workspace/pages/register/import-patents.vue?macro=true";
import { default as indexukqxLqNESEMeta } from "/workspace/pages/register/index.vue?macro=true";
import { default as join_45workspace4N40YY7aIrMeta } from "/workspace/pages/register/join-workspace.vue?macro=true";
import { default as new_45workspaceICMkI97d3PMeta } from "/workspace/pages/register/new-workspace.vue?macro=true";
import { default as _91studyId_93fn2pEcMMK0Meta } from "/workspace/pages/research/edit/[studyId].vue?macro=true";
import { default as researchlT5GQcyYA3Meta } from "/workspace/pages/research.vue?macro=true";
import { default as index67rzhcGQnxMeta } from "/workspace/pages/settings/account/index.vue?macro=true";
import { default as notificationslToYYFlBNcMeta } from "/workspace/pages/settings/account/notifications.vue?macro=true";
import { default as accountciOgd6l6U4Meta } from "/workspace/pages/settings/account.vue?macro=true";
import { default as workspacetXjRHZp2XrMeta } from "/workspace/pages/settings/workspace.vue?macro=true";
import { default as settingsp6gTdPz8G5Meta } from "/workspace/pages/settings.vue?macro=true";
export default [
  {
    path: admintMKYXMmtvkMeta?.path ?? "/admin",
    children: [
  {
    name: corpusgOlfOVbgzOMeta?.name ?? "admin-corpus",
    path: corpusgOlfOVbgzOMeta?.path ?? "corpus",
    meta: corpusgOlfOVbgzOMeta || {},
    alias: corpusgOlfOVbgzOMeta?.alias || [],
    redirect: corpusgOlfOVbgzOMeta?.redirect || undefined,
    component: () => import("/workspace/pages/admin/corpus.vue").then(m => m.default || m)
  },
  {
    name: index8x3i9klHioMeta?.name ?? "admin",
    path: index8x3i9klHioMeta?.path ?? "",
    meta: index8x3i9klHioMeta || {},
    alias: index8x3i9klHioMeta?.alias || [],
    redirect: index8x3i9klHioMeta?.redirect || undefined,
    component: () => import("/workspace/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: patent_45updateshffxULJ6RqMeta?.name ?? "admin-patent-updates",
    path: patent_45updateshffxULJ6RqMeta?.path ?? "patent-updates",
    meta: patent_45updateshffxULJ6RqMeta || {},
    alias: patent_45updateshffxULJ6RqMeta?.alias || [],
    redirect: patent_45updateshffxULJ6RqMeta?.redirect || undefined,
    component: () => import("/workspace/pages/admin/patent-updates.vue").then(m => m.default || m)
  },
  {
    name: workspacesG42vvgxlcIMeta?.name ?? "admin-workspaces",
    path: workspacesG42vvgxlcIMeta?.path ?? "workspaces",
    meta: workspacesG42vvgxlcIMeta || {},
    alias: workspacesG42vvgxlcIMeta?.alias || [],
    redirect: workspacesG42vvgxlcIMeta?.redirect || undefined,
    component: () => import("/workspace/pages/admin/workspaces.vue").then(m => m.default || m)
  }
],
    name: admintMKYXMmtvkMeta?.name ?? undefined,
    meta: admintMKYXMmtvkMeta || {},
    alias: admintMKYXMmtvkMeta?.alias || [],
    redirect: admintMKYXMmtvkMeta?.redirect || undefined,
    component: () => import("/workspace/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordd41MVLIjg6Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordd41MVLIjg6Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordd41MVLIjg6Meta || {},
    alias: forgot_45passwordd41MVLIjg6Meta?.alias || [],
    redirect: forgot_45passwordd41MVLIjg6Meta?.redirect || undefined,
    component: () => import("/workspace/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginipm5N7UCSPMeta?.name ?? "auth-login",
    path: loginipm5N7UCSPMeta?.path ?? "/auth/login",
    meta: loginipm5N7UCSPMeta || {},
    alias: loginipm5N7UCSPMeta?.alias || [],
    redirect: loginipm5N7UCSPMeta?.redirect || undefined,
    component: () => import("/workspace/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Dh1nocMFaNMeta?.name ?? "auth-reset-password-token",
    path: _91token_93Dh1nocMFaNMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93Dh1nocMFaNMeta || {},
    alias: _91token_93Dh1nocMFaNMeta?.alias || [],
    redirect: _91token_93Dh1nocMFaNMeta?.redirect || undefined,
    component: () => import("/workspace/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93iw69IV76ZxMeta?.name ?? "auth-verify-email-token",
    path: _91token_93iw69IV76ZxMeta?.path ?? "/auth/verify-email/:token()",
    meta: _91token_93iw69IV76ZxMeta || {},
    alias: _91token_93iw69IV76ZxMeta?.alias || [],
    redirect: _91token_93iw69IV76ZxMeta?.redirect || undefined,
    component: () => import("/workspace/pages/auth/verify-email/[token].vue").then(m => m.default || m)
  },
  {
    path: dashboardKIhr9Z9Xg1Meta?.path ?? "/dashboard",
    children: [
  {
    name: index2lrBtCOh90Meta?.name ?? "dashboard",
    path: index2lrBtCOh90Meta?.path ?? "",
    meta: index2lrBtCOh90Meta || {},
    alias: index2lrBtCOh90Meta?.alias || [],
    redirect: index2lrBtCOh90Meta?.redirect || undefined,
    component: () => import("/workspace/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: overviewrZ0oFTCuW0Meta?.name ?? "dashboard-overview",
    path: overviewrZ0oFTCuW0Meta?.path ?? "overview",
    children: [
  {
    name: add_45patentu4JCIxFKncMeta?.name ?? "dashboard-overview-add-patent",
    path: add_45patentu4JCIxFKncMeta?.path ?? "add-patent",
    meta: add_45patentu4JCIxFKncMeta || {},
    alias: add_45patentu4JCIxFKncMeta?.alias || [],
    redirect: add_45patentu4JCIxFKncMeta?.redirect || undefined,
    component: () => import("/workspace/pages/dashboard/overview/add-patent.vue").then(m => m.default || m)
  }
],
    meta: overviewrZ0oFTCuW0Meta || {},
    alias: overviewrZ0oFTCuW0Meta?.alias || [],
    redirect: overviewrZ0oFTCuW0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/dashboard/overview.vue").then(m => m.default || m)
  },
  {
    name: timelineueXOanD89oMeta?.name ?? "dashboard-timeline",
    path: timelineueXOanD89oMeta?.path ?? "timeline",
    meta: timelineueXOanD89oMeta || {},
    alias: timelineueXOanD89oMeta?.alias || [],
    redirect: timelineueXOanD89oMeta?.redirect || undefined,
    component: () => import("/workspace/pages/dashboard/timeline.vue").then(m => m.default || m)
  }
],
    name: dashboardKIhr9Z9Xg1Meta?.name ?? undefined,
    meta: dashboardKIhr9Z9Xg1Meta || {},
    alias: dashboardKIhr9Z9Xg1Meta?.alias || [],
    redirect: dashboardKIhr9Z9Xg1Meta?.redirect || undefined,
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    path: inventionsKsTaqEKHSjMeta?.path ?? "/inventions",
    children: [
  {
    name: indexnCsYthJgBdMeta?.name ?? "inventions",
    children: [
  {
    name: _91questionId_937VVUn16vakMeta?.name ?? "inventions-index-create-questionId",
    path: _91questionId_937VVUn16vakMeta?.path ?? "create/:questionId()",
    meta: _91questionId_937VVUn16vakMeta || {},
    alias: _91questionId_937VVUn16vakMeta?.alias || [],
    redirect: _91questionId_937VVUn16vakMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index/create/[questionId].vue").then(m => m.default || m)
  },
  {
    name: indexfhdNhWN6PyMeta?.name ?? "inventions-index-create",
    path: indexfhdNhWN6PyMeta?.path ?? "create",
    meta: indexfhdNhWN6PyMeta || {},
    alias: indexfhdNhWN6PyMeta?.alias || [],
    redirect: indexfhdNhWN6PyMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index/create/index.vue").then(m => m.default || m)
  },
  {
    name: _91disclosureId_93LQBHKGPJJ9Meta?.name ?? "inventions-index-edit-disclosureId",
    path: _91disclosureId_93LQBHKGPJJ9Meta?.path ?? "edit/:disclosureId()",
    children: [
  {
    name: _91questionId_93irOR8igLjUMeta?.name ?? "inventions-index-edit-disclosureId-questionId",
    path: _91questionId_93irOR8igLjUMeta?.path ?? ":questionId()",
    meta: _91questionId_93irOR8igLjUMeta || {},
    alias: _91questionId_93irOR8igLjUMeta?.alias || [],
    redirect: _91questionId_93irOR8igLjUMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index/edit/[disclosureId]/[questionId].vue").then(m => m.default || m)
  }
],
    meta: _91disclosureId_93LQBHKGPJJ9Meta || {},
    alias: _91disclosureId_93LQBHKGPJJ9Meta?.alias || [],
    redirect: _91disclosureId_93LQBHKGPJJ9Meta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index/edit/[disclosureId].vue").then(m => m.default || m)
  },
  {
    name: flows6eQFqgagZjMeta?.name ?? "inventions-index-flows",
    path: flows6eQFqgagZjMeta?.path ?? "flows",
    children: [
  {
    name: _91flowId_93zcmZkzBkE1Meta?.name ?? "inventions-index-flows-edit-flowId",
    path: _91flowId_93zcmZkzBkE1Meta?.path ?? "edit/:flowId()",
    meta: _91flowId_93zcmZkzBkE1Meta || {},
    alias: _91flowId_93zcmZkzBkE1Meta?.alias || [],
    redirect: _91flowId_93zcmZkzBkE1Meta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index/flows/edit/[flowId].vue").then(m => m.default || m)
  }
],
    meta: flows6eQFqgagZjMeta || {},
    alias: flows6eQFqgagZjMeta?.alias || [],
    redirect: flows6eQFqgagZjMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index/flows.vue").then(m => m.default || m)
  }
],
    path: indexnCsYthJgBdMeta?.path ?? "",
    meta: indexnCsYthJgBdMeta || {},
    alias: indexnCsYthJgBdMeta?.alias || [],
    redirect: indexnCsYthJgBdMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/index.vue").then(m => m.default || m)
  },
  {
    name: patent_45draftsGV6mJ1yR4PMeta?.name ?? "inventions-patent-drafts",
    path: patent_45draftsGV6mJ1yR4PMeta?.path ?? "patent-drafts",
    children: [
  {
    name: _91patentDraftId_939eID63Tqb0Meta?.name ?? "inventions-patent-drafts-edit-patentDraftId",
    path: _91patentDraftId_939eID63Tqb0Meta?.path ?? "edit/:patentDraftId()",
    meta: _91patentDraftId_939eID63Tqb0Meta || {},
    alias: _91patentDraftId_939eID63Tqb0Meta?.alias || [],
    redirect: _91patentDraftId_939eID63Tqb0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/patent-drafts/edit/[patentDraftId].vue").then(m => m.default || m)
  }
],
    meta: patent_45draftsGV6mJ1yR4PMeta || {},
    alias: patent_45draftsGV6mJ1yR4PMeta?.alias || [],
    redirect: patent_45draftsGV6mJ1yR4PMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions/patent-drafts.vue").then(m => m.default || m)
  }
],
    name: inventionsKsTaqEKHSjMeta?.name ?? undefined,
    meta: inventionsKsTaqEKHSjMeta || {},
    alias: inventionsKsTaqEKHSjMeta?.alias || [],
    redirect: inventionsKsTaqEKHSjMeta?.redirect || undefined,
    component: () => import("/workspace/pages/inventions.vue").then(m => m.default || m)
  },
  {
    path: patentsLc1QXiL9YTMeta?.path ?? "/patents",
    children: [
  {
    name: _91patentId_93TwZn2oo2LvMeta?.name ?? "patents-patentId",
    path: _91patentId_93TwZn2oo2LvMeta?.path ?? ":patentId()",
    children: [
  {
    name: add_45reminder3o9MJzFNbtMeta?.name ?? "patents-patentId-add-reminder",
    path: add_45reminder3o9MJzFNbtMeta?.path ?? "add-reminder",
    meta: add_45reminder3o9MJzFNbtMeta || {},
    alias: add_45reminder3o9MJzFNbtMeta?.alias || [],
    redirect: add_45reminder3o9MJzFNbtMeta?.redirect || undefined,
    component: () => import("/workspace/pages/patents/[patentId]/add-reminder.vue").then(m => m.default || m)
  },
  {
    name: _91reminderId_93Mb1ldraEapMeta?.name ?? "patents-patentId-edit-reminder-reminderId",
    path: _91reminderId_93Mb1ldraEapMeta?.path ?? "edit-reminder/:reminderId()",
    meta: _91reminderId_93Mb1ldraEapMeta || {},
    alias: _91reminderId_93Mb1ldraEapMeta?.alias || [],
    redirect: _91reminderId_93Mb1ldraEapMeta?.redirect || undefined,
    component: () => import("/workspace/pages/patents/[patentId]/edit-reminder/[reminderId].vue").then(m => m.default || m)
  },
  {
    name: edituxNj3Bv85bMeta?.name ?? "patents-patentId-edit",
    path: edituxNj3Bv85bMeta?.path ?? "edit",
    meta: edituxNj3Bv85bMeta || {},
    alias: edituxNj3Bv85bMeta?.alias || [],
    redirect: edituxNj3Bv85bMeta?.redirect || undefined,
    component: () => import("/workspace/pages/patents/[patentId]/edit.vue").then(m => m.default || m)
  }
],
    meta: _91patentId_93TwZn2oo2LvMeta || {},
    alias: _91patentId_93TwZn2oo2LvMeta?.alias || [],
    redirect: _91patentId_93TwZn2oo2LvMeta?.redirect || undefined,
    component: () => import("/workspace/pages/patents/[patentId].vue").then(m => m.default || m)
  },
  {
    name: indexyRGpepmXrnMeta?.name ?? "patents",
    path: indexyRGpepmXrnMeta?.path ?? "",
    meta: indexyRGpepmXrnMeta || {},
    alias: indexyRGpepmXrnMeta?.alias || [],
    redirect: indexyRGpepmXrnMeta?.redirect || undefined,
    component: () => import("/workspace/pages/patents/index.vue").then(m => m.default || m)
  }
],
    name: patentsLc1QXiL9YTMeta?.name ?? undefined,
    meta: patentsLc1QXiL9YTMeta || {},
    alias: patentsLc1QXiL9YTMeta?.alias || [],
    redirect: patentsLc1QXiL9YTMeta?.redirect || undefined,
    component: () => import("/workspace/pages/patents.vue").then(m => m.default || m)
  },
  {
    name: _91token_93KmJNaGd7gAMeta?.name ?? "register-accept-invite-token",
    path: _91token_93KmJNaGd7gAMeta?.path ?? "/register/accept-invite/:token()",
    meta: _91token_93KmJNaGd7gAMeta || {},
    alias: _91token_93KmJNaGd7gAMeta?.alias || [],
    redirect: _91token_93KmJNaGd7gAMeta?.redirect || undefined,
    component: () => import("/workspace/pages/register/accept-invite/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Ah708XYd80Meta?.name ?? "register-accept-workspace-request-token",
    path: _91token_93Ah708XYd80Meta?.path ?? "/register/accept-workspace-request/:token()",
    meta: _91token_93Ah708XYd80Meta || {},
    alias: _91token_93Ah708XYd80Meta?.alias || [],
    redirect: _91token_93Ah708XYd80Meta?.redirect || undefined,
    component: () => import("/workspace/pages/register/accept-workspace-request/[token].vue").then(m => m.default || m)
  },
  {
    name: confirm_45accounte1aFoPDN1cMeta?.name ?? "register-confirm-account",
    path: confirm_45accounte1aFoPDN1cMeta?.path ?? "/register/confirm-account",
    meta: confirm_45accounte1aFoPDN1cMeta || {},
    alias: confirm_45accounte1aFoPDN1cMeta?.alias || [],
    redirect: confirm_45accounte1aFoPDN1cMeta?.redirect || undefined,
    component: () => import("/workspace/pages/register/confirm-account.vue").then(m => m.default || m)
  },
  {
    name: import_45patentsqi0LEQtRPBMeta?.name ?? "register-import-patents",
    path: import_45patentsqi0LEQtRPBMeta?.path ?? "/register/import-patents",
    meta: import_45patentsqi0LEQtRPBMeta || {},
    alias: import_45patentsqi0LEQtRPBMeta?.alias || [],
    redirect: import_45patentsqi0LEQtRPBMeta?.redirect || undefined,
    component: () => import("/workspace/pages/register/import-patents.vue").then(m => m.default || m)
  },
  {
    name: indexukqxLqNESEMeta?.name ?? "register",
    path: indexukqxLqNESEMeta?.path ?? "/register",
    meta: indexukqxLqNESEMeta || {},
    alias: indexukqxLqNESEMeta?.alias || [],
    redirect: indexukqxLqNESEMeta?.redirect || undefined,
    component: () => import("/workspace/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: join_45workspace4N40YY7aIrMeta?.name ?? "register-join-workspace",
    path: join_45workspace4N40YY7aIrMeta?.path ?? "/register/join-workspace",
    meta: join_45workspace4N40YY7aIrMeta || {},
    alias: join_45workspace4N40YY7aIrMeta?.alias || [],
    redirect: join_45workspace4N40YY7aIrMeta?.redirect || undefined,
    component: () => import("/workspace/pages/register/join-workspace.vue").then(m => m.default || m)
  },
  {
    name: new_45workspaceICMkI97d3PMeta?.name ?? "register-new-workspace",
    path: new_45workspaceICMkI97d3PMeta?.path ?? "/register/new-workspace",
    meta: new_45workspaceICMkI97d3PMeta || {},
    alias: new_45workspaceICMkI97d3PMeta?.alias || [],
    redirect: new_45workspaceICMkI97d3PMeta?.redirect || undefined,
    component: () => import("/workspace/pages/register/new-workspace.vue").then(m => m.default || m)
  },
  {
    name: researchlT5GQcyYA3Meta?.name ?? "research",
    path: researchlT5GQcyYA3Meta?.path ?? "/research",
    children: [
  {
    name: _91studyId_93fn2pEcMMK0Meta?.name ?? "research-edit-studyId",
    path: _91studyId_93fn2pEcMMK0Meta?.path ?? "edit/:studyId()",
    meta: _91studyId_93fn2pEcMMK0Meta || {},
    alias: _91studyId_93fn2pEcMMK0Meta?.alias || [],
    redirect: _91studyId_93fn2pEcMMK0Meta?.redirect || undefined,
    component: () => import("/workspace/pages/research/edit/[studyId].vue").then(m => m.default || m)
  }
],
    meta: researchlT5GQcyYA3Meta || {},
    alias: researchlT5GQcyYA3Meta?.alias || [],
    redirect: researchlT5GQcyYA3Meta?.redirect || undefined,
    component: () => import("/workspace/pages/research.vue").then(m => m.default || m)
  },
  {
    name: settingsp6gTdPz8G5Meta?.name ?? "settings",
    path: settingsp6gTdPz8G5Meta?.path ?? "/settings",
    children: [
  {
    path: accountciOgd6l6U4Meta?.path ?? "account",
    children: [
  {
    name: index67rzhcGQnxMeta?.name ?? "settings-account",
    path: index67rzhcGQnxMeta?.path ?? "",
    meta: index67rzhcGQnxMeta || {},
    alias: index67rzhcGQnxMeta?.alias || [],
    redirect: index67rzhcGQnxMeta?.redirect || undefined,
    component: () => import("/workspace/pages/settings/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationslToYYFlBNcMeta?.name ?? "settings-account-notifications",
    path: notificationslToYYFlBNcMeta?.path ?? "notifications",
    meta: notificationslToYYFlBNcMeta || {},
    alias: notificationslToYYFlBNcMeta?.alias || [],
    redirect: notificationslToYYFlBNcMeta?.redirect || undefined,
    component: () => import("/workspace/pages/settings/account/notifications.vue").then(m => m.default || m)
  }
],
    name: accountciOgd6l6U4Meta?.name ?? undefined,
    meta: accountciOgd6l6U4Meta || {},
    alias: accountciOgd6l6U4Meta?.alias || [],
    redirect: accountciOgd6l6U4Meta?.redirect || undefined,
    component: () => import("/workspace/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: workspacetXjRHZp2XrMeta?.name ?? "settings-workspace",
    path: workspacetXjRHZp2XrMeta?.path ?? "workspace",
    meta: workspacetXjRHZp2XrMeta || {},
    alias: workspacetXjRHZp2XrMeta?.alias || [],
    redirect: workspacetXjRHZp2XrMeta?.redirect || undefined,
    component: () => import("/workspace/pages/settings/workspace.vue").then(m => m.default || m)
  }
],
    meta: settingsp6gTdPz8G5Meta || {},
    alias: settingsp6gTdPz8G5Meta?.alias || [],
    redirect: settingsp6gTdPz8G5Meta?.redirect || undefined,
    component: () => import("/workspace/pages/settings.vue").then(m => m.default || m)
  }
]